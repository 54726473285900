// Header.jsx
import React from 'react';

function Header() {
  return (
    <header>
      <h1>README Maker from Manikanta</h1>
    </header>
  );
}

export default Header;
